.undefined-header {
  display: flex;
  flex: none;
  align-items: center;
  height: 40px;
  padding: 0px 12px 0px;
  color: rgba(0, 0, 0, 0.88);
  background: #ffffff;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  border-radius: 8px 8px 0 0;
  margin-inline-end: 4px;
  gap: 4px;
}

.undefined-body-search-wrapper {
    position: relative;
  flex: none;
  padding: 12px;
}
.undefined-header-title {
  overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
flex: auto;
  text-align: end;
}
.anticon-search {
  color: rgba(0, 0, 0, 0.25);
}

.undefined-content {
  flex: auto;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style: none;
  max-height: 300px;
}
.undefined-content-item {
  display: flex;
  align-items: center;
  min-height: 32px;
  padding: 0px 12px;
  transition: all 0.3s;
}
.undefined-content-item:hover {
  cursor: pointer;
  background-color: #f5f5f5;

}
.ant-checkbox-wrapper {
  margin-inline-end: 8px;
}

.undefined-content-item-text {
  overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
flex: auto;
}

.undefined-checkbox {
  display: none;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  text-align: center;
  padding: 8px;
}


.tableFixHead {
  overflow-y: auto; /* make the table scrollable if height is more than 200 px  */
  height: 200px; /* gives an initial height of 200px to the table */
}
.tableFixHead thead th {
  position: sticky; /* make the table heads sticky */
  background-color: #f2f2f2;

  top: 0px; /* table head will be placed from the top of the table and sticks to it */
}