.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.ant-space-item {
  /* inherit width from parent */
  /* width: inherit; */
}